import React from "react"

import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import Card from "../card"
import BrandName from "../brand-name"

import AvatarDVO from "../../images/avatar-dvo.svg"
import AvatarDoc from "../../images/avatar-doc.svg"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <div className="container">
            <Column classes={['intro']}>
                <TextBlock title="Der richtige Zugang {b}für alle.{/b}">
                    <p><BrandName /> bringt Vorteile für alle Akteure, sowohl für Praxisbetreiber als auch für die technischen Beteiligten.</p>
                </TextBlock>
            </Column>
        </div>
        <div className="container">
            <div className="cards cards--two">
                <Column classes={['cards__column']}>
                    <Card title="Für Dienstleister vor Ort" link={{ url: '/dvo/', full: true }} image={<img src={AvatarDVO} alt="DVO" />}>
                        <p>Dienstleister vor Ort (DVO) profitieren von einer umfangreichen Schulung sowie von erstklassiger Technik und Software.</p>
                    </Card>
                </Column>
                <Column classes={['cards__column']}>
                    <Card title="Für Ärzte &amp; Klinken" link={{ url: '/praxen/', full: true }} image={<img src={AvatarDoc} alt="Ärzte &amp; Kliniken" />}>
                        <p>Mit <BrandName /> erfolgt die Anbindung an die Telematikinfrastruktur effizient aus einer Hand – mit nur einem Ansprechpartner.</p>
                    </Card>
                </Column>
            </div>
        </div>
    </Section>
)
