import React from "react"
import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"

import Image from '../../images/graphic-infrastructure.svg'

export default ({ classes, id, children }) => (
    <Section classes={classes} id={id}>
        <Column classes={['column--image']}>
            <img src={Image} alt="Grafik: Infrastruktur" />
        </Column>
        <Column classes={['column--copy']}>
            <TextBlock title="{b}220.000 Praxen,{/b} eine Infra&shy;struktur." classes={['text-block--limited']}>
                <p>Alle Akteure des Gesundheitswesens werden miteinander vernetzt. Damit wird der Grundstein für ein zukunftsfähiges, digitales Gesundheitssystem gelegt. Für sichere Kommunikation und den Schutz sensibler Informationen werden die Teilnehmer mittels eines Konnektors über einen VPN-Zugangsdienst an die Telematikinfrastruktur angeschlossen. Diese Anbindung ist für kassenärztliche Praxen in Deutschland verpflichtend.</p>
            </TextBlock>
        </Column>
    </Section>
)
