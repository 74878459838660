import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import HeroImage from "../components/images/hero"
import Section from "../components/section"
import Column from "../components/column"
import TextBlock from "../components/text-block"
import BrandName from "../components/brand-name"

import {default as SectionInfrastructure} from "../components/sections/infrastructure"
import {default as SectionFast} from "../components/sections/fast"
import {default as SectionPartners} from "../components/sections/partners"
import {default as SectionAudience} from "../components/sections/audience"

export default () => (
    <Layout>
        <SEO title="Mein Zugangsdienst – Telematik, aber einfach | Jetzt bestellen" />
        <Section classes={['text-large section--hero section--vcenter']}>
            <Column classes={['column--copy']}>
                <TextBlock title="Telematik, {b}aber einfach.{/b}" level={1} classes={['text-block--limited']}>
                    <p>Das Gesundheitswesen vernetzt sich. <BrandName /> bringt Ärzte und Patienten im Handumdrehen in die Zukunft der Telematikinfrastruktur.</p>
                    <Link to="/#infrastruktur" className="button button--secondary button--scroll">Mehr erfahren</Link>
                </TextBlock>
            </Column>
            <Column classes={['column--image']}>
                <HeroImage />
            </Column>
        </Section>
        <SectionInfrastructure classes={['text-large section--vcenter section--alternate section--masked section--default']} id="infrastruktur" />
        <SectionFast classes={['text-large section--vcenter section--alternate section--default']} />
        <SectionPartners classes={['section--masked']} text={false} />
        <SectionAudience classes={['section--alternate section--masked section--overlap']} />
    </Layout>
)
