import React from "react"
import { Link } from "gatsby"
import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import BrandName from "../brand-name"

import Image from '../../images/graphic-fast.svg'

export default ({ classes, id, children }) => (
    <Section classes={classes} id={id}>
        <Column classes={['column--image']}>
            <img src={Image} alt="Grafik: Stoppuhr" />
        </Column>
        <Column classes={['column--copy']}>
            <TextBlock title="Schnellere Installation, {b}mehr Zeit für Patienten.{/b}" classes={['text-block--limited']}>
                <p><BrandName /> sorgt für eine effiziente Installation der <abbr title="Telematikinfrastruktur">TI</abbr> in der Arztpraxis – durch optimierte Logistikabläufe, umfangreiche Schulungen für <Link to="/dvo/">Dienstleister vor Ort</Link> sowie Vorkonfiguration der Konnektoren. Mit der Erbringung aller Leistungen aus einer Hand werden unnötige Abstimmungen von technischen und logistischen Beteiligten sowie zeitliche Verzögerungen vermieden. Weniger Installationszeit bedeutet weniger Einschränkungen im Praxisbetrieb und damit mehr Zeit für die Patienten.</p>
            </TextBlock>
        </Column>
    </Section>
)
